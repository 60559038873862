import { EnvironmentType, IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
    enableMenu: false,
    gameServerUrl: "https://preprod.games.moustache.marcovasco.fr/#/",
    inatictivityTime: 2,
    mapServerUrl: "https://preprod.maps.moustache.marcovasco.fr",
    mediaAssetsPath: "media",
    mediaInstagramAssetsPath: "instagrams",
    moustacheApiUrl: "https://preprod.api.moustache.marcovasco.fr",
    paymentsUrl: "https://preprod.payments.marcovasco.fr/payments/comptoir_des_saveurs",
    production: false,
    siteAssetsUrl: "https://c0.marcovasco.fr",
    swiperDelay: 10000,
    type: EnvironmentType.PREPROD,
};
